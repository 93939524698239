.container {
    margin-top: 1rem;
    padding: 20px 20px 20px 20px;
    border: .3px solid silver;
    box-shadow: 1px 1px 4px rgba(101, 87, 87, 0.26);
    border-radius: 10px;
}

.title {
    font-size: 18px;
    font-weight: 800;
    color: #1b1b1b;
    font-family: FiraSans-Bold!important;
}

.status {
    background: #AD0039!important;
    color: white;
    font-size: 14px;
    font-family: FiraSans-Light!important;
    padding: 7px 10px 7px 10px;
}

.next {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 14px;
}

.next:hover {
    font-weight: bold;
}

.optionItem {
    font-size: 12px;
}

.option {
    border-radius: 50%;
    font-size: 12px;
    margin-left: .5rem;
    padding: 2px;
    width: 30px;
    height: 30px;
    background: rgb(88, 88, 88);
    color: white;
    border-color: rgb(88, 88, 88);
}

.option::after {
    content: none;
}

.navIcon {
    color: silver;
    font-size: 14px;
    cursor: pointer;
}

.active {
    color: #AD0039;
    transform: scale(1.5);
    transition: transform 1s ease-in-out;
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.boton-over {
    position: absolute;
    right: 1rem;
    top: auto;
    background-color: darkred;
    color: white;
}