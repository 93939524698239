.next {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 14px;
    font-family: FiraSans-Regular!important;
}

.next:focus {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 14px;
    font-family: FiraSans-SemiBold!important;
}

.next:hover {
    font-weight: bold;
}

.groupLabelDarker {
    font-family: FiraSans-SemiBold!important;
    font-size: 16px;
    color: #680a2a;
}

.groupLabel {
    font-family: FiraSans-SemiBold!important;
    font-size: 16px;
    color: #AD0039;
}

.label {
    font-family: FiraSans-Regular!important;
    font-size: 12px;
    color: black;
}

.modalCenter {
    position: absolute;
    top: 100px;
    left: 30%;
    width: 38%;
    padding: 20px 1%;
    z-index: 1000;
    background-color: white;
    border-color: silver;
    border-style: solid;  
    border-width: 1px;
}

.minimodalCrimes{
    position: absolute;
    background-color: white;
    border-color: silver;
    border-style: solid;  
    border-width: 1px;
}

.input {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    border-radius: 0px;
    height: calc(3rem + 2px)!important;
}

.textarea {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    border-radius: 0px;
    resize: vertical;
}

.checkboxLabel {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    color: black;
}

.checkbox {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    color: black;
    accent-color: #AD0039;
}

.checkbox:checked {
    background-color: #AD0039;
    border-color: #AD0039;    
}

.table {
    width: 100%;
}

.table thead th {
    font-size: 10px;
    font-family: FiraSans-Regular!important;
    font-weight: normal;
    text-align: left;
    text-align: center;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: center;
}

.badgeContainer {
    padding-right: 0px;
}

.btnActionContainer {
    padding: 0px;
    margin-top: auto;    
}

.btnAction {
    background-color: transparent;
    border: 0px solid transparent;
    color: gray;
}

.fileInput {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    border-radius: 0px;    
}

.lawNumberSpan {
    font-size: 12px;
    font-family: FiraSans-Light!important;
}

.lawNameSpan {
    font-size: 14px;
    font-family: FiraSans-Medium!important;
}

.pillsGroup {
    background: transparent;
    padding-bottom: 7px;
    margin-bottom: 1rem;
}

.pill {
    font-size: 12px;
}

.pillLink {
    padding: 5px 10px 5px 10px;
}

.pill :global(.nav-link):global(.active){
    background-color: #AD0039;
    color: white;
    font-weight: bold;
}

.pill :global(.nav-link){
    color: rgb(64, 64, 64);
}

.badgeSelected {
    background: #AD0039!important;
    color: white!important;
    border: 0px solid transparent;
    margin-bottom: .3rem;
    font-family: FiraSans-Regular!important;
    font-size: 12px;    
}

.badgeDefault {
    background: white!important;
    border: 1px solid silver!important;
    color: gray!important;    
    margin-bottom: .3rem;
    font-family: FiraSans-Light!important;
    font-size: 12px;
}

.icon {
    font-size: 17px;
    cursor: pointer;
}

.icon:hover {
    color: #AD0039!important;
}

.iconBtn {
    font-size: 12px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}

.groupLabelTitle {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}