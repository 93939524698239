:root {
    --label-title-color: #3E3A3A;
    --label-group-title-color: #3E3A3A;
    --input-color: #3E3A3A;
}

.input {
    width: auto;
    font-size: 14px;
    font-weight: 200;
    background: transparent;
    border: 0px solid transparent;
    color: var(--input-color);
    font-family: FiraSans-Light!important;
}

.table {
    width: 100%;
}

.table thead th {
    font-size: 12px;
    font-family: FiraSans-Regular!important;
    font-weight: 500!important;
    text-align: left;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: left;
    padding-left: 2px;
}

.iconBtn {
    font-size: 12px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}

.label {
    text-align: left;
    font-size: 12px;
    color: var(--label-title-color);
    font-family: FiraSans-Regular!important;
}

.groupLabel {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.group {
    margin-bottom: 1rem;
}

.pillsGroup {
    background: transparent;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    padding-bottom: 7px;
    margin-bottom: 1rem;
}

.pill {
    font-size: 12px;
}

.pillLink {
    padding: 5px 10px 5px 10px;
}

.pill :global(.nav-link):global(.active){
    background-color: #AD0039;
    color: white;
    font-weight: bold;
}

.pill :global(.nav-link){
    color: rgb(64, 64, 64);
}

.noContent {
    font-size: 12px;
    font-weight: 500;
}

.judgmentType {
    font-size: 11px;
    font-family: 'FiraSans-Regular'!important;
}

.judgmentFile {
    font-size: 11px;
    font-family: 'FiraSans-Regular'!important;
    cursor: pointer;
}

.bordeauxButton {
    background-color: transparent;
    color: #AD0039;
    border: none;
    padding: 0.5rem 1rem;
  }
  
  .bordeauxButton:hover {
    background-color: transparent;
    color: #AD0039;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }